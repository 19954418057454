'use strict'

angular.module 'nn.app.routes', []

    .config ($stateProvider, $urlRouterProvider, $locationProvider, $urlMatcherFactoryProvider) ->

        $urlRouterProvider.otherwise '/'

        $urlMatcherFactoryProvider.strictMode false

        $locationProvider.html5Mode true


        $stateProvider

            .state 'login',
                url: '/login'
                templateUrl: '/templates/login.html'
                onEnter: ($state, $location, $timeout, Auth, AppState) ->
                    params = $location.search()
                    if params['code']
                        onLoginSuccess = ->
                            if params['state']
                                $location.search({})
                                $location.path params['state']
                            else
                                $state.go 'app.dashboard'
                            delayStateFn = () -> AppState.login.loading = false
                            $timeout delayStateFn, 1000
                        onLoginError = (data, status) ->
                            AppState.login.loading = false
                            AppState.login.error = data
                        AppState.login.loading = true
                        Auth.cognito(params['code']).then onLoginSuccess, onLoginError

            .state 'logout',
                url: '/logout'

            .state 'feedback',
                url: 'https://docs.google.com/a/fairfaxmedia.com.au/forms/d/1Q0Ck4tQ9kfECoMZQBiZwDVHk3RWNZGcbxWntLy_NcPg/viewform?usp=send_form'

            .state 'communities',
                url: 'https://docs.google.com/document/d/1ieZiRl45xhosdJgUO9JUmh6xgktWV39cvYpjP32obsY/edit'

            .state 'app',
                url: '/'
                abstract: true
                views:
                    '':
                        templateUrl: '/templates/app.html'
                        controller: ($scope, AppState, Explorer, user, Sections, FeatureAccess, FeatureAccessTags, NotificationService, ServicesAvailability, ServicesCheck, OneTimeNotification, User, Profile, ProfileAPI) ->
                            $scope.explorer = Explorer
                            $scope.state = AppState
                            $scope.ServicesNotAvailable = ServicesAvailability.data.ServicesNotAvailable
                            User.getMastheads().then (data) ->
                                mastheads = data.objects.map (domain) ->
                                    if /(www)(.*)(com|net)/g.exec(domain.domain)
                                        /(www)(.*)(com|net)/g.exec(domain.domain)[2].replace(/\W/g, '')
                                domains = data.objects.map (domain) -> domain.domain

                                localStorage.setItem 'mastheads', JSON.stringify mastheads
                                localStorage.setItem 'domains', JSON.stringify domains

                            FeatureAccess.hasAccess().then ({siteBuilder, newsList, silverstone}) ->
                                $scope.siteBuilder = siteBuilder
                                $scope.newsList = newsList
                                htmlMarkup = '<div class="nn-notification-container" style="display:flex; flex-direction: column; justify-content: center; align-items: center;"><p style="overflow-wrap: break-word; word-wrap: break-word; hyphens: auto;">Our new Google account is going live on June 22, 2020. If you cannot access your account or have any other issues, please contact your <a href="https://cdn.newsnow.io/7nRgbqx4sEzJvKxpkFfgPN/ab7976d5-2e04-46be-a23b-280a1d528d68.pdf" style="color:blue;" target="_blank">Google guide</a> or <a href="mailto:itsupport@austcommunitymedia.com.au" style="color:blue;" target="_blank">itsupport@austcommunitymedia.com.au</a></p><p>By clicking <b>ACCEPT</b>, you won&apos;t see this message again</p>'
                                $scope.sections = Sections.map (elem) ->
                                    if elem.id == 'files'
                                        # If prod, doing Newrelic health checking to see if Valencia is healthy or not
                                        # If non-prod, sending direct request to the service (it may slightly slow down the newsnow index page loading speed, so only do this in non-prod)
                                        ServicesCheck.getHealthCheck("Valencia ").catch (e) =>
                                            elem.enabled = ServicesAvailability.data.ServicesNotAvailable
                                            NotificationService.create 'error', 'Image Management service (Valencia) is not available', 'Please contact your Local IT', 0
                                    if elem.id  == 'stories'
                                        elem.enabled = silverstone
                                        if silverstone is ServicesAvailability.data.ServicesNotAvailable
                                            NotificationService.create 'error', 'Story Management Service (Silverstone) is not available', 'Please contact your Local IT', 0
                                    if elem.id == 'newslist2' || elem.id == 'newslist'
                                        elem.enabled = newsList
                                    if elem.id == 'site'
                                        elem.enabled = siteBuilder
                                        if siteBuilder is ServicesAvailability.data.ServicesNotAvailable
                                            NotificationService.create 'error', 'Web Management Service (Suzuka) is not available', 'Please contact your Local IT', 0
                                    elem
                                OneTimeNotification.callService("GSuiteMigration").then (data) ->
                                    if data.results.length is 0
                                        NotificationService.confirmWithHyperlink('', htmlMarkup).then ->
                                            OneTimeNotification.updateService("GSuiteMigration")
                                htmlMarkupAuthorProfile = '<div class="nn-notification-container" style="display:flex; flex-direction: column; justify-content: center; align-items: center;"><p style="overflow-wrap: break-word; word-wrap: break-word; hyphens: auto; font-weight: bold;">You have not completed your author profile</p>'
                                { first_name, last_name, email } = $scope.user
                                defaultUserData =
                                    name: "#{first_name} #{last_name}"
                                    email: email
                                    position: ''
                                    mugshot: ''
                                ProfileAPI.load().then (profiles) ->
                                    userData = profiles?[0] or defaultUserData
                                    $scope.profile = Profile.getInstance userData
                                    if  !$scope.profile.data.id and window.location.pathname isnt '/profile'
                                        NotificationService.confirmWithCustomAction('', htmlMarkupAuthorProfile).then ->
                                            window.location.href = '/profile'
                                    window.markerConfig =
                                        project: '65dd6beba866370de835b75d',
                                        source: 'snippet',
                                        reporter:
                                          fullName: userData.name
                                          email: userData.email
                                    if $scope.profile?.data?.default_organization
                                      FeatureAccess.hasFeatureAccess(FeatureAccessTags.PRINT_PACKAGE, {user: 'self', org: $scope.profile.data.default_organization}).then (result) ->
                                        if result
                                          markerEl = document.createElement("script");
                                          markerEl.type = "text/javascript"
                                          markerEl.text = '!function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);'
                                          document.body.appendChild(markerEl);
                            $scope.user = user
                        resolve:
                            user: (Auth) ->
                                Auth.user()
                            adonis: (user, AdonisService) ->
                                AdonisService.$queryStream

                    'nav@app':
                        templateUrl: '/templates/nav.html'
                        controller: ($scope, $state, AppState, Explorer) ->
                            $scope.explorer = Explorer
                            $scope.isActive = (section) ->
                                Explorer.data.active and (AppState.section.id is section.id)
                            $scope.selectSection = (section) ->
                                AppState.section = section
                    'explorer@app':
                        templateUrl: '/templates/explorer.html'
                    'editor@app':
                        templateUrl: '/templates/editor.html'
                        controller: ($scope) ->
                            $scope.mobile = "ios"
                            $("#select-mobile-device").val("ios")
                            $scope.changeMobile = () ->
                                if $("#select-mobile-device").val() is "ios"
                                    $("#story-layout").removeClass()
                                    $("#story-layout").addClass("mobile")
                                    return
                                else
                                    $("#story-layout").removeClass()
                                    $("#story-layout").addClass("mobile-android")
                                    return

                onEnter: ($state, Auth, Roles, User) ->
                    # prefetch user publications
                    Roles.attachRoles().then ->
                        if Roles.canGo 'pages'
                            User.getPublications()

            .state 'app.dashboard',
                url: ''
                onEnter: ($timeout, Explorer, AppState, Sections, NotificationService) ->
                    AppState.section = Sections[0]
                    AppState.canvas = 'dashboard'

            .state 'app.profile',
                url: 'profile'
                onEnter: (AppState, Sections) ->
                    AppState.section = Sections[9]
                    AppState.canvas = 'profile'

            .state 'app.health',
                url: 'health'
                onEnter: (AppState, Sections) ->
                    AppState.section = Sections[9]
                    AppState.canvas = 'health'
