'use strict'

angular.module 'nn.story-editor.services', []

    .factory 'OvuUiService', ($log, $q) ->
        instance = null
        showStatus = false
        class OvuUi
            messageDeferred = null
            constructor: (frame) ->
                @iframe = frame
                messageDeferred = $q.defer()
                messageDeferred.resolve()

            sendMessage: (type, value) ->
                messageDeferred.promise.then =>
                    data = JSON.stringify {type, value}
                    @iframe.postMessage data, '*'

        getInstance: (frame) ->
            if instance == null && typeof frame == 'undefined'
                throw new Error('OvuUi instanciation issue')
            instance = new OvuUi frame
            instance

    .constant 'OvuUiMessageType',
        STORY_MODEL: 'storyModel'
        ENVIRONMENT: 'environment'

    .constant 'OvuUiRequestMessageType',
        READY: 'ready'
        CLOSE: 'close'

    .factory 'StorypadService', ($log, $q, $http, HOSTS) ->
        instance = null
        class Storypad
            messageDeferred = null
            constructor: (frame) ->
                @iframe = frame
                @url = null
                @loaded = false
                @saving = false
                @storyChangeDueToSave = false
                @editingImage = false
                @storyModel = {}
                @images = []
                messageDeferred = $q.defer()
                messageDeferred.resolve()
                @_listeners = {
                    modelChange: []
                    imageChange: []
                }


            # called on keyUp from storyPad and onChange from React
            updateStoryModelField: (name, value) =>
                switch name
                    when 'title'
                        @storyModel.title = value
                    when 'summary'
                        @storyModel.summary = value
                    when 'social_title'
                        if (@storyModel.body)
                            @storyModel.body.social_title = value
                    when 'social_summary'
                        if (@storyModel.body)
                            @storyModel.body.social_summary = value
                    when 'social_url'
                        if (@storyModel.body)
                            @storyModel.body.social_url = value
                    when 'story_body'
                        if (@storyModel.body)
                            @storyModel.body.elements = value
                    when 'seo_title'
                        if @storyModel.body
                            @storyModel.body.title_seo = value.trim()
                    when 'seo_description'
                        if @storyModel.body
                            @storyModel.body.seo_description = value.trim()

                @trigger 'modelChange'

            trigger: (name) =>
                switch name
                    when 'modelChange'
                        for cb, i in @_listeners.modelChange
                            cb @storyModel
                    when 'imageChange'
                        for cb, i in @_listeners.imageChange
                            cb @images

            addImage: (image) =>
                @images =  @images.concat [image]
                @trigger 'imageChange'

            removeImage: (image) =>
                @images = @images.filter (img) -> img.uri != image.uri
                @trigger 'imageChange'

            rotateImage: (token, imageId, bucketName, degree) =>
                deferred = $q.defer()
                $http
                    method: 'POST'
                    url: "#{HOSTS.valencia}/transpose/#{imageId}/?degree=#{degree}&bucket=#{bucketName}"
                    headers: 'Authorization':"Bearer #{token}"
                    cache: false
                    auth: true
                .success (result) =>
                    deferred.resolve result
                .error (e) =>
                    deferred.reject e
                deferred.promise
            on: (eventName, callback) =>
                @_listeners[eventName].push callback

            getImages: =>
                @images

            sendMessage: (type, value) ->
                messageDeferred.promise.then =>
                    data = JSON.stringify {type, value}
                    @iframe.postMessage data, '*'
                    # $log.log 'MANAGE CLIENT: [sent message]', type, value

            loadStory: (storyModel) ->
                if typeof storyModel == 'undefined'
                    @storyModel =
                        title: ''
                        summary: ''
                        body:
                            social_title: ''
                            social_summary: ''
                            social_url: ''
                    @images = []
                else
                    @storyModel = storyModel
                    if @storyModel.body && @storyModel.body.elements.length > 0
                        @images = @storyModel.body.elements.filter (element) -> element.type == 'image'
                    else
                        @images = []
                @trigger 'modelChange'
                @trigger 'imageChange'

            changeStory: (story, oldStory) ->
                if story?.id?
                    storypadURL = "/storypad/#{story.id}"
                    storypadURL += "/#{story.snapshot}" if story.snapshot?
                else
                    storypadURL = "/storypad/new"
                if !@url?
                    @url = storypadURL
                else
                    @loaded = false
                    @iframe.location.pathname = storypadURL
            destroy: ->
                @iframe = null
        getInstance: (frame) ->
            if instance == null && typeof frame == 'undefined'
                throw new Error('StoryPad instanciation issue')
            instance = new Storypad frame
            instance

    .constant 'StorypadEvent',
        MESSAGE_RECEIVED: 'messageReceived'

    .constant 'StorypadMessageType',
        TOKEN: 'token'
        TOKEN_REFRESHED: 'tokenRefreshed'
        USER: 'user'
        TASK: 'task'
        BUCKET: 'bucket'
        HOSTS: 'hosts'
        SETTINGS: 'settings'
        ANALYTICS: 'analytics'
        FIGURES: 'figures'
        SAVE: 'save'
        TOGGLE_UI: 'toggleUi'
        SOCIAL: 'social'
        ADD_SOCIAL_TAG: 'addSocialTag'
        STORY_NOT_SAVE: 'storyNotSave'
        GET_LATEST_BODY: 'getLatestBody'
        HAS_OVU_ACCESS: 'hasOvuAccess'
        STORY_LOADED: 'storyLoaded'
        UPDATED_STORY_RESPONSE: 'updatedStoryResponse'
        UPDATE_PUBLISH_FROM: 'updatePublishFrom'
        ROTATE_IMAGE: 'rotateImage'
        SHOW_OLD_SEO_TITLE: 'showOldSeoTitle'
        SHOW_NEW_SEO_TITLE: 'showNewSeoTitle'
        UPDATE_SEO_TITLE: 'updateSeoTitle'
        UPDATE_SEO_DESCRIPTION: 'updateSeoDescription'
        UPDATE_STORY_STATUS: 'updateStoryStatus'
        SET_SEO: 'setSeo'
        UNSET_SEO_DESCRIPTION: 'unsetSeoDescription'
        UNSET_SEO_TITLE: 'unsetSeoTitle'
        SET_DIGITAL_QUALITY_CHECKLIST: 'setDigitalQualityChecklist'
        SET_REGENERATE_COPYFIT_ELEMENTS: 'setRegenerateCopyfitElements'
        SET_PRINT_PREVIEW_PATH: 'setPrintPreviewPath'
        SET_IS_PROMPTED_FOR_SOCIAL_PUBLISHING: 'setIsPromptedForSocialPublishing'
        UPDATE_CANONICAL_URL: 'updateCanonicalUrl'

    .constant 'StorypadRequestType',
        TOKEN: 'tokenRequest'
        TOKEN_REFRESH: 'tokenRefreshRequest'
        USER: 'userRequest'
        TASK: 'taskRequest'
        BUCKET: 'bucketRequest'
        HOSTS: 'hostsRequest'
        SETTINGS: 'settingsRequest'
        ANALYTICS: 'analyticsRequest'
        FIGURES: 'figuresRequest'
        STORY_SAVE: 'storySaveRequest'
        STORY_LOAD: 'storyLoadRequest'
        EDIT_IMAGE: 'editImageRequest'
        EDIT_IMAGE_COMPLETE: 'editImageCompleteRequest'
        TEXT_FIELD_UPDATE: 'updateTextField'
        ADD_IMAGE: 'addImage'
        REMOVE_IMAGE: 'removeImage'
        UPDATE_STORY_BODY:'updateStoryBody'
        ACTIVATE_OVU_UI: 'activateOvuUi'
        HAS_OVU_ACCESS: 'hasOvuAccess'
        ROTATE_IMAGE: 'rotateImage'
        DIJON_API_STATUS: 'dijonApiStatus'
        UPDATE_TARGETLISTS: 'updateTargetlists'
        SHOW_BREAKING_CONFIRM_BOX: 'showBreakingConfirmBox'
        BREAKINGNEWS_DATE_VALIDATION: 'breakingnewsDateValidation'
        UPDATE_STORY_PUBLISH_STATE: 'updateStoryPublishState'
        SOURCE_UPDATED: 'sourceUpdated'
        DIGITAL_QUALITY_CHECK: 'digitalQualityCheck'
        STORY_ON_SAVE_IS_INVALID: 'storyOnSaveIsInvalid'
        DISPLAYING_SETTINGS_PANEL: 'displayingSettingsPanel'
        SANITIZE_CANONICAL_URL: 'sanitizeCanonicalUrl'

    .constant 'SocialMediaName',
        FACEBOOK: 'Facebook'
